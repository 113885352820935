import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import ChipInput from 'material-ui-chip-input';
import Select from 'react-select';
import { FormControl, TextField, FormGroup, FormLabel, FormControlLabel, Switch } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

// Field for basic input text
export function TextInput(props) {
    return (
        <>
            <TextField
                multiline={props.multiline}
                rows={props.rows}
                label={props.label}
                name={props.name}
                placeholder={props.placeholder}
                value={props.value}
                defaultValue={props.defaultValue}
                variant="outlined"
                className={`${props.className} ${props.errors? "error-style" : ""}`}
                inputRef={props.validation}
                disabled={props.disabled}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={props.inputProps}
                onBlur={props.onBlur}
            />
            {props.errors && <p className={props.errorClassName}>{props.errors.message}</p>}
        </>
    )
}

// Field for phone number input
export function PhoneInput(props) {
    return (
        <>
            <TextField
                label={props.label}
                name={props.name}
                placeholder={props.placeholder}
                variant="outlined"
                className={`${props.className} ${props.errors? "error-style" : ""}`}
                disabled={props.disabled}
                inputRef={props.validation({
                    required: (props.requiredText),
                    pattern: {
                        value: /^[+-]?\d+$/,
                        message: "*format tidak valid",
                    },
                    minLength: {
                        value: 8,
                        message: "*nomor telepon terlalu pendek",
                    },
                    maxLength: {
                        value: 16,
                        message: "*nomor telepon terlalu panjang",
                    },
                })}
                InputLabelProps={{
                  shrink: true,
                }}
            />
            {props.errors && <p className={props.errorClassName}>{props.errors.message}</p>}
        </>
    )
}

// Field for upload image and show the preview
export function ImageInput(props) {
    const [state, setState] = useState({
      image: null,
      imgPath: 'Pilih Gambar',
      canceledImage: false,
      deleteResponse: 0,
    });
    const max = 2097152;
    const defaultImage = props.defaultImg
      ? props.defaultImg
      : require("../assets/img/imageDefault.png").default;
  
    const validateImage = value => {
      return value[0] ? value[0].size <= max : props.isEdit === true;
    };
  
    const inputImage = event => {
      let obj = new FormData();
      obj.append("image", event.target.files[0]);
      setState({
        ...state,
        image: URL.createObjectURL(event.target.files[0]),
        formData: obj,
        imgPath: event.target.files[0].name,
        deleteResponse: 0,
      });
    };
  
    const clearImage = async event => {
        if ( props.defaultImg ) {
            if ( props.name.includes('product_slider') ) {
                props.storeDeletedImage(props.name);
                document.getElementById("selectedFile_" + props.name).value = null;
                setState({
                    ...state,
                    image: require("../assets/img/imageDefault.png").default,
                    formData: null,
                    imgPath: "Pilih Gambar",
                    canceledImage: true,
                    deleteResponse: 200,
                });
            }
            else if ( props.name.includes('company_banner') ) {
                props.storeDeletedImage(props.name);
                document.getElementById("selectedFile_" + props.name).value = null;
                setState({
                    ...state,
                    image: require("../assets/img/imageDefault.png").default,
                    formData: null,
                    imgPath: "Pilih Gambar",
                    canceledImage: true,
                    deleteResponse: 200,
                });
            }
            else {
                alert('Foto utama harus ada! Hanya dapat melakukan penggantian foto.')
            }
        }
        else {
            document.getElementById("selectedFile_" + props.name).value = null;
            setState({
                ...state,
                image: require("../assets/img/imageDefault.png").default,
                formData: null,
                imgPath: "Pilih Gambar",
                canceledImage: true,
            });
        }
    };
  
    return (
      <div className={props.overallClassName}>
          <div className={`delete-img-btn ${props.disabled? "hide" : ""}
            ${(props.defaultImg? "" : state.canceledImage? "hide" : state.image? "" : "hide")}
            ${state.deleteResponse === 200? "hide":""}`}>
              <CloseIcon
                onClick={() => clearImage()}
                className="can-delete"
                // className={props.defaultImg || state.image? "can-delete" : "cannot-delete"}
              />
          </div>
          <img
            width="100%"
            style={{ width:"10rem", height:"10rem", display:"block", marginLeft:"auto", marginRight:"auto" }}
            src={state.image ? state.image : defaultImage}
            alt="img"
          />
          <div className={props.disabled? "" : "image-info"}>
            <p
                style={{ marginBottom: "10px", color: "#592c0d",
                textAlign: (props.disabled? "center" : "left") }}
            ><b>{props.label}</b></p>
            <div>
                <label>
                    <input
                    style={{ display: "none" }}
                    accept="image/*"
                    type="file"
                    size="60"
                    name={props.name}
                    onChange={inputImage}
                    disabled={props.disabled}
                    id={"selectedFile_" + props.name}
                    ref={
                        !props.isEdit
                        ? props.validation({
                            required: props.error_label + " harus diisi",
                            validate: value =>
                                validateImage(value) ||
                                "Gambar yang anda masukkan melebihi batas maksimal (2MB)"
                            })
                        : props.validation({
                            required: false,
                            validate: value =>
                                validateImage(value) ||
                                "Gambar yang anda masukkan melebihi batas maksimal (2MB)"
                            })
                    }
                    />
                    <div className={(props.disabled? "hide" : "")}>
                        <span className="img-name">{state.imgPath}</span>
                        <span className="browse-btn">Browse</span>
                    </div>
                </label>
                {props.errors && (
                    <span style={{ color:"red", width:"100%", display:"inherit", marginTop:"7px" }}>
                    <small>{props.errors.message}</small>
                    </span>
                )}
            </div>
          </div>
      </div>
    );
}

// Field for boolean input
export function SwitchInput(props) {
    return (
        <>
            <FormControl
                variant="outlined"
                component="fieldset"
                className={props.className}
                disabled={props.disabled}
            >
                <FormLabel component="legend" className="legend-switch">
                    {props.legend}
                </FormLabel>
                <FormGroup>
                    <FormControlLabel
                        control={
                            <Switch
                                checked={props.checked}
                                onChange={props.onChange}
                                name={props.name}
                                value={props.value}
                                inputRef={props.validation}
                            />
                        }
                        label={props.label}
                        className="fcl-switch"
                    />
                </FormGroup>
            </FormControl>
        </>
    );
}

// Field for price
export function PriceInput(props) {
    return (
        <>
            <label className="price-label">{props.label}</label>
            <Controller
                as={
                    <NumberFormat
                      customInput={TextField}
                      thousandSeparator="."
                      decimalSeparator=","
                      prefix={"Rp "}
                      className={props.className}
                      placeholder="Rp 0"
                      disabled={props.disabled}
                    />
                  }
                  name={props.name}
                  defaultValue=""
                  variant="outlined"
                  control={props.control}
            />
            {props.errors && <p className={props.errorClassName}>{props.errors.message}</p>}
        </>
    );
}

// Field for grouped dropdown
export function GroupedDropdown(props) {
    return (
        <>
            <label className="grouped-label">{props.label}</label>
            <Controller
                as={Select}
                name={props.name}
                className={props.className}
                options={props.options}
                control={props.control}
                rules={props.rules}
                disabled={props.disabled}
                defaultValue=""
                isMulti
            />
            {props.errors && <p className={props.errorClassName}>{props.errors.message}</p>}
        </>
    );
}

// Field for tags / multiple value input
export function TagsInput(props) {
    return (
        <>
            <ChipInput
                label={props.label}
                name={props.name}
                className={props.className}
                placeholder={props.placeholder}
                newChipKeys={['Enter', ' ']}
                variant="outlined"
                value={props.value}
                onAdd={props.onAdd}
                onDelete={props.onDelete}
                disabled={props.disabled}
                InputLabelProps={{
                    shrink: true,
                }}
            />
        </>
    );
}