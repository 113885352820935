import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import MuiAlert from '@material-ui/lab/Alert';
import whiteLogo from '../../assets/img/rentatoy-logo-white.png';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import '../../styles/Form_Styles.css';
import Sidebar from '../../components/Sidebar_Com';
import Spinner from '../../components/Loading_Com';
import { getCategories, postProduct } from '../../logic/APIHandler';
import { TextInput, GroupedDropdown, ImageInput, PriceInput, TagsInput } from '../../logic/FormInput';
import { Grid, Button, Snackbar } from '@material-ui/core';
import _ from 'underscore';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AddProduct() {

    const history = useHistory();
    const { register, handleSubmit, errors, control } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [data, setData] = useState([]);
    const [eState, setEState] = useState("");
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const windowWidth = window.innerWidth;
    const [state, setState] = useState({
        tags: [],
    });

    // Make the website stay still for __ ms
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }

    // Close success toast
    const handleCloseSuccess = (event, reason) => {
        setOpenSuccessAlert(false);
    };
    // Close error toast
    const handleCloseError = (event, reason) => {
        setOpenErrorAlert(false);
    };

    // Handle delete and add tags
    const handleDeleteChip = (chip) => {
        setState({
            tags: _.without(state.tags, chip)
        })
    }
    const handleAddChip = (chip) => {
        setState({
            tags: [...state.tags, chip]
        });
    }

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }
    
    // Handle onChange text editor value
    const onEditorStateChange = (editorState) => {
        setEState(editorState);
    };

    // Handle onBlur product code field
    const handleProductCode = (event) => {
        let value = event.target.value;
        if ( value && value.length === 1 ) {
            event.target.value = "00" + value;
        }
        else if ( value && value.length === 2 ) {
            event.target.value = "0" + value;
        }
    }

    // Method that run first when the page is opened 
    useEffect(() => {
        // Create default value for text editor
        const html = '';
        const contentBlock = htmlToDraft(html);
        if (contentBlock) {
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            const editorState = EditorState.createWithContent(contentState);
            setEState(editorState);
        }
        // Get list of categories
        getData();
    }, []);

    // Get category list
    const getData = async data => {
        setIsLoading(true);
        let categories = await getCategories();
        if (categories[0]) {
            const categoryList = categories[0].data;
            let res = [];
            categoryList.forEach(category => {
                let subs = [];
                category.sub_category.forEach(sub_categ => {
                    if ( sub_categ.sub !== "Musical Mobile & Hanging Toys" ) {
                        subs.push({
                            'id': sub_categ.id,
                            'label': sub_categ.sub,
                            'value': sub_categ.sub,
                        })
                    }
                })
                res.push({
                    'label': category.category,
                    'options': subs,
                })
            });
            setData(res);
        }
        else {
            console.log("Can't load categories or still loading.")
        }
        setIsLoading(false);
    }

    // Send new product data to server
    const onSubmit = async (data, event) => {
        if ( !event.nativeEvent.submitter.className.includes('delete-btn') ) {
            setIsLoading(true);
            let description = draftToHtml(convertToRaw(eState.getCurrentContent()));
            let resp = await postProduct(data, description, state.tags);
            if (resp[0] && resp[0].status === 200) {
                setOpenSuccessAlert(true);
                await sleep(1000);
                history.replace("/produk");
            }
            else {
                setOpenErrorAlert(true);
                setIsLoading(false);
            }
        }
    }

    return (
        <>
            <div className="content">
                { isLoading ? ( <Spinner /> ) : "" }
                <Sidebar sidebarState={(windowWidth < 768)? isSidebarOpen : ""} />

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <img src={whiteLogo} alt="logo" className="header-logo" />
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={toggleSidebar}>
                            {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                        </Button>
                    </Grid>
                </Grid>

                <Grid container className="grid-container">
                    <Snackbar open={openSuccessAlert} autoHideDuration={2500} onClose={handleCloseSuccess}>
                        <Alert severity="success">
                            Berhasil Membuat Produk Baru
                        </Alert>
                    </Snackbar>
                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseError}>
                        <Alert severity="error">
                            Gagal Membuat Produk Baru
                        </Alert>
                    </Snackbar>
                    <Grid item xs={12}>
                        <form onSubmit={handleSubmit(onSubmit)} className="add-form">
                            <h1 style={{ marginBottom: "2rem" }}>Tambah Produk Baru</h1>

                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    {/* Input for name */}
                                    <TextInput
                                        label="Nama Produk *"
                                        name="title"
                                        placeholder="Nama"
                                        className="form-left-field"
                                        validation={register({ required: "*nama harus diisi" })}
                                        errors={errors.title}
                                        errorClassName="text-required-half"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {/* Input for kode produk */}
                                    <TextInput
                                        label="Kode Produk *"
                                        name="product_code"
                                        placeholder="Contoh: 001"
                                        className="form-right-field"
                                        validation={register({
                                            required: "*kode produk harus diisi",
                                            pattern: {
                                                value: /^[0-9]*$/,
                                                message: "*input hanya boleh berupa angka",
                                            },
                                            minLength: {
                                                value: 3,
                                                message: "*kode produk tidak boleh kurang dari 3 digit angka",
                                            },
                                        })}
                                        inputProps={{
                                            maxLength: 3,
                                        }}
                                        onBlur={handleProductCode}
                                        errors={errors.product_code}
                                        errorClassName="text-required-half half-right"
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ marginBottom: "20px" }}>
                                    {/* Input for description */}
                                    <Editor
                                        placeholder="Tulis deskripsi barang dengan lengkap di sini"
                                        editorState={eState}
                                        wrapperClassName="demo-wrapper"
                                        editorClassName="demo-editor"
                                        onEditorStateChange={onEditorStateChange}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {/* Input for price */}
                                    <PriceInput
                                        label="Harga Produk"
                                        name="price"
                                        className="full-width add-margin-bottom price-field"
                                        control={control}
                                    />
                                </Grid>
                                <Grid item xs={12} style={{ zIndex: "99" }}>
                                    {/* Input for categories */}
                                    <GroupedDropdown
                                        label="Kategori Produk *"
                                        name="categories"
                                        className="full-width add-margin-bottom grouped-field"
                                        control={control}
                                        options={data}
                                        rules={{ required: "*pilih minimal 1 kategori" }}
                                        errors={errors.categories}
                                        errorClassName="text-required"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {/* Input for tags */}
                                    <TagsInput
                                        label="Tags"
                                        name="tags"
                                        placeholder="Ketik tags di sini"
                                        className="full-width"
                                        value={state.tags}
                                        onAdd={(chip) => handleAddChip(chip)}
                                        onDelete={(chip, index) => handleDeleteChip(chip, index)}
                                    />
                                    <p className="tag-info">* Tekan enter/spasi untuk memasukkan tag</p>
                                </Grid>
                            </Grid>

                            <Grid container className="product-gallery">
                                <Grid item xs={12}>
                                    <h2>Foto Utama</h2>
                                    <p className="input-img-terms">
                                        * Tipe file yang diperbolehkan hanya JPG/PNG (maks. 2MB)
                                    </p>
                                </Grid>
                                <Grid item xs={12} md={3} lg={4}></Grid>
                                <Grid item xs={12} md={6} lg={4} className="add-margin-bottom">
                                    <ImageInput
                                        name="product_image"
                                        label="Foto Produk *"
                                        validation={register}
                                        errors={errors.product_image}
                                        isEdit={false}
                                        control={control}
                                        defaultImg={false}
                                        error_label="*foto produk harus diisi"
                                    />
                                </Grid>
                                <Grid item xs={12} md={3} lg={4}></Grid>
                            </Grid>

                            <Grid container className="product-gallery">
                                <Grid item xs={12}>
                                    <h2>Foto Tambahan</h2>
                                    <p className="input-img-terms">
                                        * Tipe file yang diperbolehkan hanya JPG/PNG (maks. 2MB)
                                    </p>
                                </Grid>
                                <Grid item xs={12} md={6} lg={4} className="add-margin-bottom">
                                    <ImageInput
                                        name="product_slider_1"
                                        label="Foto 1"
                                        validation={register}
                                        isEdit={true}
                                        control={control}
                                        defaultImg={false}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={4} className="add-margin-bottom">
                                    <ImageInput
                                        name="product_slider_2"
                                        label="Foto 2"
                                        validation={register}
                                        isEdit={true}
                                        control={control}
                                        defaultImg={false}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} lg={4} className="add-margin-bottom">
                                    <ImageInput
                                        name="product_slider_3"
                                        label="Foto 3"
                                        validation={register}
                                        isEdit={true}
                                        control={control}
                                        defaultImg={false}
                                    />
                                </Grid>
                            </Grid>

                            <Button
                                type="submit"
                                variant="contained"
                                style={{ display: "block", marginLeft: "auto" }}
                                className="add-new-btn"
                            >Submit</Button>
                        </form>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}