import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Grid, Button } from "@material-ui/core";
import whiteLogo from '../../assets/img/rentatoy-logo-white.png';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import DeleteIcon from '@material-ui/icons/Delete';
import Sidebar from '../../components/Sidebar_Com';
import Table from '../../components/Table_Com';
import Modal from '../../components/Modal_Com';
import Spinner from '../../components/Loading_Com';
import { getCustomers } from '../../logic/APIHandler';

class Customer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            open: false,
            openJWT: false,
            customerId: '00000000',
            customerName: '',
            isLoading: false,
            isSidebarOpen: 'slide-out',
        }
        this.windowWidth = window.innerWidth;
        this.toggleSidebar = this.toggleSidebar.bind(this);
        this.handleClose = this.handleClose.bind(this);
    }

    columns = [
        { 
            title: "ID", 
            field: "customer_id",
            tooltip: "ID kustomer",
            render: rowData =>
                rowData.customer_id !== "0"?
                    ( <p>{rowData.customer_id}</p> ) : ( <p>-</p> )
        },
        { 
            title: "Nama", 
            field: "name_person",
            tooltip: "Nama kustomer",
            cellStyle: {
                width: "280px",
                maxWidth: "280px"
            },
        },
        {
            title: "No Telpon",
            field: "phone_person",
            tooltip: "Nomor telpon kustomer",
        },
        { 
            title: "Alamat", 
            field: "address",
            tooltip: "Alamat kustomer",
            cellStyle: {
                width: "380px",
                maxWidth: "380px"
            },
        },
    ]

    actions = [
        {
            icon: () => <FindInPageIcon />,
            tooltip: "Lihat Detil Kustomer",
            onClick: (event, rowData) => {
                this.props.history.push({
                    pathname: "/kustomer/" + rowData._id,
                  })
            }
        },
        {
            icon: () => <DeleteIcon />,
            tooltip: "Hapus Kustomer",
            onClick: (event, rowData) => {
                this.handleOpen(rowData);
            }
        },
    ]

    // Show delete customer modal
    handleOpen(customer) {
        this.setState({
            open: true,
            customerId: customer._id,
            customerName: customer.name_person,
        })
    }

    // CLose delete customer modal
    handleClose() {
        this.setState({
            open: false,
        })
    }

    // Toggle sidebar menu in responsive
    toggleSidebar() {
        if ( this.state.isSidebarOpen === 'slide-out' ) {
            this.setState({
                isSidebarOpen: 'slide-in',
            });
        }
        else {
            this.setState({
                isSidebarOpen: 'slide-out',
            });
        }
    }

    async getData() {
        this.setState({ isLoading: true });
        let customers = await getCustomers();
        if ( customers[1] && customers[1].status == 401) {
            this.setState({
                openJWT: true,
                isLoading: false,
            });
        }
        else {
            this.setState({
                data: customers[0]? customers[0].data : [],
                isLoading: false,
            })
        }
    }

    componentDidMount() {
        localStorage.removeItem("productPage");
        this.getData();
    }

    render() {
        return (
            <div className="content">
                {/* { this.state.isLoading ? ( <Spinner /> ) : "" } */}
                <Sidebar sidebarState={(this.windowWidth < 768)? this.state.isSidebarOpen : ""} />

                <Modal
                    open={this.state.open}
                    onClose={this.handleClose}
                    onClickCancel={this.handleClose}
                    modalType="delete-customer"
                    id={this.state.customerId}
                    color="secondary"
                    modalTitle="Hapus kustomer?"
                    dataName={this.state.customerName}
                    submitText="Delete"
                    cancelText="Cancel"
                    successText="Berhasil menghapus kustomer!"
                    errorText="Gagal menghapus kustomer!"
                />

                <Modal
                  open={this.state.openJWT}
                  modalType="handle-jwt"
                />

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <img src={whiteLogo} alt="logo" className="header-logo" />
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={this.toggleSidebar}>
                            {this.state.isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                        </Button>
                    </Grid>
                </Grid>

                { this.state.isLoading ? ( <Spinner /> ) : <Grid container className="grid-container">
                    <Grid item xs={12} className="material-table">
                        <Table
                            tableTitle="Tabel Kustomer"
                            tableColumns={this.columns}
                            data={this.state.data}
                            tableActions={this.actions}
                            type="customer"
                            customerPage={localStorage.getItem("customerPage")?
                                localStorage.getItem("customerPage") : 0}
                            customerSearch={localStorage.getItem("customerSearch")?
                                localStorage.getItem("customerSearch") : ""}
                        />
                    </Grid>
                    <Grid item xs={12} className="table-footer">
                        <NavLink className="btn-link" to="/kustomer-form" style={{ textDecoration: "none" }}>
                            <Button variant="contained" className="add-btn-table">
                                    Tambah Kustomer
                            </Button>
                        </NavLink>
                    </Grid>
                </Grid> }
            </div>
        );
    }
}

export default Customer;