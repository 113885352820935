import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';
import './styles/Default_Styles.css';
import './styles/Colour_Styles.css';
import './assets/fonts/Fonts.css';

import LoginPage from './pages/Login_Page';
import ProductPage from './pages/product/Product_Page';
import AddProductPage from './pages/product/AddProduct_Page';
import DetailProductPage from './pages/product/DetailProduct_Page';
import BannerPage from './pages/banner/Banner_Page';
import CustomerPage from './pages/customer/Customer_Page';
import AddCustomerPage from './pages/customer/AddCustomer_Page';
import DetailCustomerPage from './pages/customer/DetailCustomer_Page';
import ChangePasswordPage from './pages/ChangePassword_Page';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={LoginPage}/>
        <PrivateRoute exact path="/produk" component={ProductPage}/>
        <PrivateRoute exact path="/produk/:id?" component={DetailProductPage}/>
        <PrivateRoute exact path="/produk-form" component={AddProductPage}/>
        <PrivateRoute exact path="/banner" component={BannerPage}/>
        <PrivateRoute exact path="/kustomer" component={CustomerPage}/>
        <PrivateRoute exact path="/kustomer-form" component={AddCustomerPage}/>
        <PrivateRoute exact path="/kustomer/:id?" component={DetailCustomerPage}/>
        <PrivateRoute exact path="/change-password" component={ChangePasswordPage}/>
      </Switch>
    </Router>
  );
}

export default App;