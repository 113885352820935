import Axios from "axios";
const BASE_URL = "https://api.rental-mainan.com/api/v1/";

/**
 * Translate error code to message
 * @param error: error code (ex: 404)
 */
 const handleError = error => {
    let errorResponse = null;
    if (error.code === "ECONNABORTED") {
        errorResponse = {
            status: 408,
            error: [{ msg: error.message }],
            message: "Request Time Out",
        };
    }
    else if (error.response) {
        errorResponse = error.response.data;
    }
    else {
        errorResponse = {
            status: 501,
            error: [{ msg: "Server Implementation Error" }],
        };
    }
    return errorResponse;
};

/**
 * Handle user login
 * @param data: data required to login
 */
 export const hitLogin = async data => {
    var errorResponse = null;
    var tokenData;
    var status;
    let object = [];
  
    object = {
      "username": data.username,
      "password": data.password,
    }
  
    await Axios({
      method: "post",
      url: "auth/login",
      baseURL: BASE_URL,
      data: object,
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
    })
      .then(function(response) {
        tokenData = response.data;
        status = response.status;
      })
      .catch(function(error) {
        errorResponse = handleError(error);
        status = error;
      });
    return [tokenData, errorResponse, status];
};

/**
 * Handle user change password
 * @param data: data required to change password
 */
 export const changePassword = async data => {
    var errorResponse = null;
    var tokenData;
    var status;
    let object = [];
  
    object = {
      "old_password": data.old_password,
      "new_password": data.new_password,
    }
  
    await Axios({
      method: "put",
      url: "auth/change_password",
      baseURL: BASE_URL,
      headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
      },
      data: object,
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
    })
      .then(function(response) {
        tokenData = response.data;
        status = response.status;
      })
      .catch(function(error) {
        errorResponse = handleError(error);
        status = error;
      });
    return [tokenData, errorResponse, status];
};

// -------------------------------------------------------------------------------------------- //

/**
 * Get product categories
 */
 export const getCategories = async () => {
    var errorResponse = null;
    var tokenData;
    await Axios({
        method: "get",
        url: "category",
        baseURL: BASE_URL,
        headers: {
            "Authorization": "Bearer " + localStorage.getItem('token'),
        },
        timeout: 30000,
        timeoutErrorMessage: "Request telah melebihi 30s. Silakan coba lagi.",
    })
    .then(function(response) {
        tokenData = response.data;
    })
    .catch(function(error) {
        errorResponse = handleError(error);
    });
    return [tokenData, errorResponse];
}

// -------------------------------------------------------------------------------------------- //

/**
 * Get products data
 */
 export const getProducts = async () => {
    var errorResponse = null;
    var tokenData;
    await Axios({
        method: "get",
        url: "product",
        baseURL: BASE_URL,
        headers: {
            "Authorization": "Bearer " + localStorage.getItem('token'),
        },
        timeout: 30000,
        timeoutErrorMessage: "Request telah melebihi 30s. Silakan coba lagi.",
    })
    .then(function(response) {
        tokenData = response.data;
    })
    .catch(function(error) {
        errorResponse = handleError(error);
    });
    return [tokenData, errorResponse];
}

/**
 * Get specific product
 * @param id: product's ID
 */
 export const getProduct = async id => {
    var errorResponse = null;
    var tokenData;
    await Axios({
      method: "get",
      url: "product/" + id,
      baseURL: BASE_URL,
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
    })
      .then(function(response) {
        tokenData = response.data;
      })
      .catch(function(error) {
        errorResponse = handleError(error);
      });
    return [tokenData, errorResponse];
};

/**
 * Create new product
 * @param data: data required to create new product 
 */
 export const postProduct = async (data, description, tags) => {
    var errorResponse = null;
    var tokenData;
    var status;
    let form = new FormData();

    if ( data.categories && data.categories.length > 0 ) {
        data.categories.forEach(val => {
            form.append("categories", val.id);
        });
    }
    if ( tags ) {
      tags.forEach(val => {
        form.append("tags", val);
      });
    }
    if ( data.product_image ) form.append("product_image", data.product_image[0]);
    if ( data.product_slider_1 && data.product_slider_1.length > 0 )
      form.append("product_slider", data.product_slider_1[0]);
    if ( data.product_slider_2 && data.product_slider_2.length > 0 )
      form.append("product_slider", data.product_slider_2[0]);
    if ( data.product_slider_3 && data.product_slider_3.length > 0 )
      form.append("product_slider", data.product_slider_3[0]);
    form.append("title", data.title);
    form.append("product_code", data.product_code);
    form.append("description", description);

    let price = "";
    if ( data.price ) {
        for ( let i=0; i < data.price.length; i++ ) {
            const value = parseInt(data.price.charAt(i));
            if ( value >= 0 && value <= 9 ) {
                price += value;
            }
        }
    }
    form.append("price", price);
  
    await Axios({
      method: "post",
      url: "product",
      baseURL: BASE_URL,
      headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
      },
      data: form,
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
    })
      .then(function(response) {
        tokenData = response.data;
        status = response.status;
      })
      .catch(function(error) {
        errorResponse = handleError(error);
        status = error;
      });
    return [tokenData, errorResponse, status];
};

/**
 * Add new product slider
 * @param idProduct: product's ID
 * @param data: data required to add new product slider 
 */
 export const postProductSlider = async (idProduct, data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let form = new FormData();
  let index = [];

  if ( data.product_slider_1 && data.product_slider_1.length > 0 ) {
    form.append("product_slider", data.product_slider_1[0]);
    index.push(0);
  }
  if ( data.product_slider_2 && data.product_slider_2.length > 0 ) {
    form.append("product_slider", data.product_slider_2[0]);
    index.push(1);
  }
  if ( data.product_slider_3 && data.product_slider_3.length > 0 ) {
    form.append("product_slider", data.product_slider_3[0]);
    index.push(2);
  }
  form.append("idx_slider", "[" + index + "]");

  await Axios({
    method: "post",
    url: "product/slider/upload/" + idProduct,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    data: form,
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Update product's data
 * @param idProduct: product's ID which want to be updated
 * @param data: updated data
 */
 export const editProduct = async (idProduct, data, description, tags) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let form = new FormData();

  if ( data.categories ) {
    data.categories.forEach(val => {
        form.append("categories", val.id);
    });
  }
  if ( tags ) {
    tags.forEach(val => {
      form.append("tags", val);
    });
  }
  form.append("title", data.title);
  form.append("product_code", data.product_code);
  form.append("description", description);

  let price = "";
  if ( data.price ) {
      const stringPrice = data.price + "";
      for ( let i=0; i < stringPrice.length; i++ ) {
        const value = parseInt(stringPrice.charAt(i));
        if ( value >= 0 && value <= 9 ) {
            price += value;
        }
    }
  }
  form.append("price", price);
    
  if (data.product_image.length > 0) {
    form.append("product_image", data.product_image[0]);
  }

  await Axios({
    method: "put",
    url: "product/update/" + idProduct,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    data: form,
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Update product status' data
 * @param idProduct: product's ID which want to be updated
 * @param data: updated status
 */
 export const updateProductStatus = async (idProduct, newStatus) => {
  var errorResponse = null;
  var tokenData;
  var status;

  await Axios({
    method: "put",
    url: "product/update/status/" + idProduct,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    data: { "isAvailable" : newStatus },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Delete product slider
 * @param idProduct: product's ID 
 * @param index: product slider's index that want to be deleted 
 */
 export const deleteProductSlider = async (idProduct, index) => {
  var errorResponse = null;
  var tokenData;
  var status;

  await Axios({
      method: "delete",
      url: "product/slider/delete/" + idProduct,
      baseURL: BASE_URL,
      data: {"idx_slider": [index]},
      headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
      },
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
      .then(function(response) {
      tokenData = response.data;
      status = response.status;
      })
      .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
      });
  return [tokenData, errorResponse, status];
};

/**
 * Delete product
 * @param idProduct: product's ID that want to be deleted 
 */
 export const deleteProduct = async idProduct => {
    var errorResponse = null;
    var tokenData;
    var status;
  
    await Axios({
        method: "delete",
        url: "product/delete/" + idProduct,
        baseURL: BASE_URL,
        headers: {
            "Authorization": "Bearer " + localStorage.getItem('token'),
        },
        timeout: 30000,
        timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
    })
        .then(function(response) {
        tokenData = response.data;
        status = response.status;
        })
        .catch(function(error) {
        errorResponse = handleError(error);
        status = error;
        });
    return [tokenData, errorResponse, status];
};

// -------------------------------------------------------------------------------------------- //

/**
 * Get banner information
 */
 export const getBanner = async () => {
  var errorResponse = null;
  var tokenData;
  await Axios({
      method: "get",
      url: "config-store",
      baseURL: BASE_URL,
      headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
      },
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silakan coba lagi.",
  })
  .then(function(response) {
      tokenData = response.data;
  })
  .catch(function(error) {
      errorResponse = handleError(error);
  });
  return [tokenData, errorResponse];
}

/**
 * Add new banner image
 * @param idBanner: banner's ID
 * @param data: data required to add new banner image 
 */
 export const postBannerImage = async (idBanner, data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let form = new FormData();
  let index = [];

  if ( data.company_banner_1 && data.company_banner_1.length > 0 ) {
    form.append("company_banner", data.company_banner_1[0]);
    index.push(0);
  }
  if ( data.company_banner_2 && data.company_banner_2.length > 0 ) {
    form.append("company_banner", data.company_banner_2[0]);
    index.push(1);
  }
  if ( data.company_banner_3 && data.company_banner_3.length > 0 ) {
    form.append("company_banner", data.company_banner_3[0]);
    index.push(2);
  }
  form.append("idx_banner", "[" + index + "]");

  await Axios({
    method: "post",
    url: "config-store/banner/upload/" + idBanner,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    data: form,
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Delete banner image
 * @param idBanner: banner's ID 
 * @param index: banner image's index that want to be deleted 
 */
 export const deleteBannerImage = async (idBanner, index) => {
  var errorResponse = null;
  var tokenData;
  var status;

  await Axios({
      method: "delete",
      url: "config-store/banner/delete/" + idBanner,
      baseURL: BASE_URL,
      data: {"idx_banner": [index]},
      headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
      },
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
      .then(function(response) {
      tokenData = response.data;
      status = response.status;
      })
      .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
      });
  return [tokenData, errorResponse, status];
};

// -------------------------------------------------------------------------------------------- //

/**
 * Get customer information
 */
 export const getCustomers = async () => {
  var errorResponse = null;
  var tokenData;
  await Axios({
      method: "get",
      url: "customer",
      baseURL: BASE_URL,
      headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
      },
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silakan coba lagi.",
  })
  .then(function(response) {
      tokenData = response.data;
  })
  .catch(function(error) {
      errorResponse = handleError(error);
  });
  return [tokenData, errorResponse];
}

/**
 * Get specific customer
 * @param id: customer's ID
 */
 export const getCustomer = async id => {
  var errorResponse = null;
  var tokenData;
  await Axios({
    method: "get",
    url: "customer/" + id,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
    });
  return [tokenData, errorResponse];
};

/**
 * Create new customer
 * @param data: data required to create new customer 
 */
 export const postCustomer = async data => {
  var errorResponse = null;
  var tokenData;
  var status;
  let form = new FormData();

  if ( data.id_card ) form.append("id_card", data.id_card[0]);
  if ( data.family_card ) form.append("family_card", data.family_card[0]);
  form.append("name_person", data.name_person);
  form.append("phone_person", data.phone_person);
  form.append("name_couple", data.name_couple);
  form.append("phone_couple", data.phone_couple);
  form.append("address", data.address);
  form.append("details", data.details);
  form.append("rt_rw", data.rt_rw);
  form.append("kelurahan", data.kelurahan);
  form.append("kecamatan", data.kecamatan);
  form.append("phone_home", data.phone_home);
  form.append("term_and_condition", true);

  await Axios({
    method: "post",
    url: "customer/register",
    baseURL: BASE_URL,
    data: form,
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Update customer's data
 * @param idCustomer: customer's ID which want to be updated
 * @param data: updated data
 */
 export const editCustomer = async (idCustomer, data) => {
  var errorResponse = null;
  var tokenData;
  var status;
  let form = new FormData();

  form.append("customer_id", data.customer_id);
  form.append("name_person", data.name_person);
  form.append("phone_person", data.phone_person);
  form.append("name_couple", data.name_couple);
  form.append("phone_couple", data.phone_couple);
  form.append("address", data.address);
  form.append("details", data.details);
  form.append("rt_rw", data.rt_rw);
  form.append("kelurahan", data.kelurahan);
  form.append("kecamatan", data.kecamatan);
  form.append("phone_home", data.phone_home);

  if (data.id_card.length > 0) {
    form.append("id_card", data.id_card[0]);
  }
  if (data.family_card.length > 0) {
    form.append("family_card", data.family_card[0]);
  }

  await Axios({
    method: "put",
    url: "customer/update/" + idCustomer,
    baseURL: BASE_URL,
    headers: {
        "Authorization": "Bearer " + localStorage.getItem('token'),
    },
    data: form,
    timeout: 30000,
    timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
    .then(function(response) {
      tokenData = response.data;
      status = response.status;
    })
    .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
    });
  return [tokenData, errorResponse, status];
};

/**
 * Delete customer
 * @param idCustomer: customer's ID that want to be deleted 
 */
 export const deleteCustomer = async idCustomer => {
  var errorResponse = null;
  var tokenData;
  var status;

  await Axios({
      method: "delete",
      url: "customer/delete/" + idCustomer,
      baseURL: BASE_URL,
      headers: {
          "Authorization": "Bearer " + localStorage.getItem('token'),
      },
      timeout: 30000,
      timeoutErrorMessage: "Request telah melebihi 30s. Silahkan coba lagi."
  })
      .then(function(response) {
      tokenData = response.data;
      status = response.status;
      })
      .catch(function(error) {
      errorResponse = handleError(error);
      status = error;
      });
  return [tokenData, errorResponse, status];
};