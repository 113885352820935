import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import MuiAlert from '@material-ui/lab/Alert';
import whiteLogo from '../../assets/img/rentatoy-logo-white.png';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import '../../styles/Form_Styles.css';
import Modal from '../../components/Modal_Com';
import Sidebar from '../../components/Sidebar_Com';
import Spinner from '../../components/Loading_Com';
import { getBanner, postBannerImage, deleteBannerImage } from '../../logic/APIHandler';
import { Button, Grid, Snackbar } from '@material-ui/core';
import { ImageInput } from '../../logic/FormInput';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function Banner(props) {

    const history = useHistory();
    const { register, handleSubmit, control, } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [openJWT, setOpenJWT] = useState(false);
    const [edit, setEdit] = useState('Edit Banner');
    const [pageTitle, setPageTitle] = useState('Data Banner');
    const [hide, setHide] = useState('hide');
    const [nonHide, setNonHide] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);
    const [deletedImage, setDeletedImage] = useState([]);
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const windowWidth = window.innerWidth;
    const [state, setState] = useState({
        idBanner: "",
        company_banner_1: "",
        company_banner_2: "",
        company_banner_3: "",
    });

    // Method that run first when the page is opened 
    useEffect(() => {
        const token = localStorage.getItem("token");
        localStorage.clear();
        localStorage.setItem("token", token);
        getData();
    }, []);

    // Get banner's data from API
    const getData = async id => {
        setIsLoading(true);
        let resp = await getBanner();
        if (resp[0] && resp[0].status === 200) {
            const banner = resp[0].data;

            setState({
                ...state,
                idBanner: banner._id,
                company_banner_1: (banner.company_banner[0] !== undefined)?
                    { value: banner.company_banner[0], idx: 0 } : "",
                company_banner_2: (banner.company_banner[1] !== undefined)?
                    { value: banner.company_banner[1], idx: 1 } : "",
                company_banner_3: (banner.company_banner[2] !== undefined)?
                    { value: banner.company_banner[2], idx: 2 } : "",
            });
        }
        else if ( resp[1].status == 401 ) {
            setOpenJWT(true);
        }
        else {
            console.log("Couldn't get banner's data");
        }
        setIsLoading(false);
    }

    // Handle toggle edit button
    const onClickEdit = () => {
        if ( edit === "Edit Banner" ) {
            setEdit("Cancel");
            setPageTitle("Edit Banner")
            setHide("");
            setNonHide("hide");
            setIsDisabled(false);
        }
        else {
            history.go(0);
        }
    }

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }

    // Make the website stay still for __ ms
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }

    // Close success toast
    const handleCloseSuccess = (event, reason) => {
        setOpenSuccessAlert(false);
    };
    // Close error toast
    const handleCloseError = (event, reason) => {
        setOpenErrorAlert(false);
    };

    // Store deleted image
    const addDeletedImage = (name) => {
        let value = deletedImage.concat(name);
        setDeletedImage(value);
    }

    // Send updated data product to server
    const onSubmit = async (data, event) => {
        if ( !event.nativeEvent.submitter.className.includes('delete-btn') ) {
            setIsLoading(true);

            if ( deletedImage.includes("company_banner_1")
                || (state.company_banner_1.length > 0 && data.company_banner_1) ) {
                await deleteBannerImage(state.idBanner, 0);
            }
            if ( deletedImage.includes("company_banner_2")
                || (state.company_banner_2.length > 0 && data.company_banner_2) ) {
                await deleteBannerImage(state.idBanner, 1);
            }
            if ( deletedImage.includes("company_banner_3")
                || (state.company_banner_3.length > 0 && data.company_banner_3) ) {
                await deleteBannerImage(state.idBanner, 2);
            }

            let resp = await postBannerImage(state.idBanner, data);
            if (resp[0] && resp[0].status === 200) {
                setOpenSuccessAlert(true);
                await sleep(1000);
                history.go(0);
            }
            else {
                setOpenErrorAlert(true);
                setIsLoading(false);
            }
        }
    }

    return (
        <div className="content">
            { isLoading ? ( <Spinner /> ) : "" }
            <Sidebar sidebarState={(windowWidth < 768)? isSidebarOpen : ""} />

            <Modal
                open={openJWT}
                modalType="handle-jwt"
            />

            <Grid container className="responsive-header">
                <Grid item xs={6}>
                    <img src={whiteLogo} alt="logo" className="header-logo" />
                </Grid>
                <Grid item xs={6} className="menu-icon-btn">
                    <Button variant="outlined" onClick={toggleSidebar}>
                        {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                    </Button>
                </Grid>
            </Grid>

            <Grid container className="grid-container">
                <Snackbar open={openSuccessAlert} autoHideDuration={2500} onClose={handleCloseSuccess}>
                    <Alert severity="success">
                        Berhasil Update Banner
                    </Alert>
                </Snackbar>
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseError}>
                    <Alert severity="error">
                        Gagal Update Banner
                    </Alert>
                </Snackbar>

                <Grid item xs={12}>
                    <form onSubmit={handleSubmit(onSubmit)} className="add-form">
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <h1 style={{ marginBottom: "1rem" }}>{pageTitle}</h1>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Button
                                    variant="contained" onClick={onClickEdit}
                                    className={`edit-btn ${nonHide}`}>
                                    {edit}
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                <p className={`input-img-terms ${hide}`}>* Tipe file yang diperbolehkan hanya JPG/PNG (maks. 2MB)</p>
                            </Grid>
                        </Grid>

                        <Grid container style={{ marginTop: "2rem" }}>
                            <Grid item xs={12} md={6} lg={4} className="add-margin-bottom">
                                <ImageInput
                                    name="company_banner_1"
                                    label="Banner 1"
                                    validation={register}
                                    isEdit={true}
                                    control={control}
                                    disabled={isDisabled}
                                    defaultImg={state.company_banner_1.value}
                                    storeDeletedImage={addDeletedImage}
                                    id={state.idBanner}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={4} className="add-margin-bottom">
                                <ImageInput
                                    name="company_banner_2"
                                    label="Banner 2"
                                    validation={register}
                                    isEdit={true}
                                    control={control}
                                    disabled={isDisabled}
                                    defaultImg={state.company_banner_2.value}
                                    storeDeletedImage={addDeletedImage}
                                    id={state.idBanner}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={4} className="add-margin-bottom">
                                <ImageInput
                                    name="company_banner_3"
                                    label="Banner 3"
                                    validation={register}
                                    isEdit={true}
                                    control={control}
                                    disabled={isDisabled}
                                    defaultImg={state.company_banner_3.value}
                                    storeDeletedImage={addDeletedImage}
                                    id={state.idBanner}
                                />
                            </Grid>
                        </Grid>

                        <Grid container>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    onClick={onClickEdit}
                                    className={`add-cancel-btn ${hide}`}
                                >Cancel</Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    style={{ float: "right", marginRight: "15px" }}
                                    className={`add-new-btn responsive-submit-btn ${hide}`}
                                >Submit</Button>
                            </Grid>
                        </Grid>

                    </form>
                </Grid>
            </Grid>
        </div>
    );
}