import React, { Component } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import { List, ListItem, ListItemText, ListSubheader, } from '@material-ui/core';
import '../styles/Sidebar_Styles.css';
import logo from '../assets/img/rentatoy-logo.png';

import ListItemIcon from '@material-ui/core/ListItemIcon';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import HowToRegIcon from '@material-ui/icons/HowToReg';
import AnnouncementIcon from '@material-ui/icons/Announcement';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

class Sidebar extends Component {

  constructor(props) {
    super(props);
    
    const currLocation = props.location.pathname;
    const currID = props.match.params.id;

    if ( currLocation.includes('/pengurus') || currLocation.includes('/pembina') ) {
      this.state = {
        openPengurus: true, openAcara: false, openMitra: false, id: (currID? currID : ""),
      }
    }
    else if ( currLocation.includes('/acara-mendatang') || currLocation.includes('/acara-lampau')
      || currLocation.includes('/kategori-acara') ) {
      this.state = {
        openPengurus: false, openAcara: true, openMitra: false, id: (currID? currID : ""),
      }
    }
    else if ( currLocation.includes('/merchant-benefit') || currLocation.includes('/partner') || currLocation.includes('/sponsor') ) {
      this.state = {
        openPengurus: false, openAcara: false, openMitra: true, id: (currID? currID : ""),
      }
    }
    else {
      this.state = {
        openPengurus: false, openAcara: false, openMitra: false, id: (currID? currID : ""),
      }
    }
  }

  // Maintain sidebar active menu to stay active in different pages
  onPaths = (paths) => {
    return (match, location) => {
      return paths.includes(location.pathname);
    };
  };

  handleListItemClick = (event, index) => {
    if ( event.target.innerText === "Logout" ) {
        localStorage.clear();
    }
  };

  render() {
    return (
      <div className={`sidebar ${this.props.sidebarState}`}>
        <List
          dense
          subheader={
            <ListSubheader className="sidebar-header">
              <NavLink to="/produk" className="default-link-style">
                  <img src={logo} alt="logo" className="sidebar-logo" />
              </NavLink>
            </ListSubheader>
          }
        >
          <React.Fragment>
  
            {/* Product Link */}
            <NavLink
              activeClassName="active-link"
              exact
              isActive={this.onPaths([
                "/produk", "/produk-form", ("/produk/" + this.state.id),
              ])}
              to="/produk"
              onClick={() => {
                localStorage.removeItem("productPage");
                localStorage.removeItem("productSearch");
              }}
              style={{ textDecoration: "none" }}>
              <ListItem
                button
                className="sidebar-item"
              >
                <ListItemIcon className="sidebar-icon"><LocalMallIcon /></ListItemIcon>
                <ListItemText className="sidebar-item-text">Produk</ListItemText>
              </ListItem>
            </NavLink>
  
            {/* Banner Link */}
            <NavLink
              activeClassName="active-link"
              exact
              isActive={this.onPaths([
                "/banner", "/banner-form", ("/banner/" + this.state.id),
              ])}
              to="/banner"
              style={{ textDecoration: "none" }}>
              <ListItem
                button
                className="sidebar-item"
              >
                <ListItemIcon className="sidebar-icon"><AnnouncementIcon /></ListItemIcon>
                <ListItemText className="sidebar-item-text">Banner</ListItemText>
              </ListItem>
            </NavLink>
  
            {/* Register Link */}
            <NavLink
                activeClassName="active-link"
                exact
                isActive={this.onPaths([
                  "/kustomer", "/kustomer-form", ("/kustomer/" + this.state.id),
                ])}
              to="/kustomer"
              onClick={() => {
                localStorage.removeItem("customerPage");
                localStorage.removeItem("customerSearch");
              }}
              style={{ textDecoration: "none" }}>
              <ListItem
                button
                className="sidebar-item"
                onClick={(event) => this.handleListItemClick(event, 1)}
              >
                <ListItemIcon className="sidebar-icon"><HowToRegIcon /></ListItemIcon>
                <ListItemText className="sidebar-item-text">Kustomer</ListItemText>
              </ListItem>
            </NavLink>

            {/* Change Password Link */}
            <NavLink
                activeClassName="active-link"
                exact
                isActive={this.onPaths([
                  "/change-password",
                ])}
              to="/change-password"
              style={{ textDecoration: "none" }}>
              <ListItem
                button
                className="sidebar-item"
                onClick={(event) => this.handleListItemClick(event, 1)}
              >
                <ListItemIcon className="sidebar-icon"><VpnKeyIcon /></ListItemIcon>
                <ListItemText className="sidebar-item-text">Ganti Password</ListItemText>
              </ListItem>
            </NavLink>
  
            {/* Logout Link */}
            <NavLink to="/" style={{ textDecoration: "none" }}>
              <ListItem
                button
                className="sidebar-item"
                onClick={(event) => this.handleListItemClick(event, 2)}
              >
                <ListItemIcon className="sidebar-icon"><ExitToAppIcon /></ListItemIcon>
                <ListItemText className="sidebar-item-text">Logout</ListItemText>
              </ListItem>
            </NavLink>
  
          </React.Fragment>
        </List>
      </div>
    );

  }
}

export default withRouter(Sidebar);