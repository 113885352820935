import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import MuiAlert from '@material-ui/lab/Alert';
import whiteLogo from '../../assets/img/rentatoy-logo-white.png';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import '../../styles/Form_Styles.css';
import Modal from '../../components/Modal_Com';
import Sidebar from '../../components/Sidebar_Com';
import Spinner from '../../components/Loading_Com';
import { getCustomer, editCustomer } from '../../logic/APIHandler';
import { Button, Grid, Snackbar } from '@material-ui/core';
import { TextInput, PhoneInput, ImageInput, } from '../../logic/FormInput';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function DetailCustomer(props) {

    const history = useHistory();
    const { register, handleSubmit, errors, control, setValue } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [openJWT, setOpenJWT] = useState(false);
    const [edit, setEdit] = useState('Edit Kustomer');
    const [pageTitle, setPageTitle] = useState('Detil Kustomer');
    const [hide, setHide] = useState('hide');
    const [nonHide, setNonHide] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);
    const [idCustomer, setIDCustomer] = useState(null);
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const windowWidth = window.innerWidth;
    const [state, setState] = useState({
        ktp_photo: "",
        kk_photo: "",
    });

    // Method that run first when the page is opened 
    useEffect(() => {
        const idCustomer = props.match.params.id;
        setIDCustomer(idCustomer);
        if ( idCustomer ) {
            getData(idCustomer);
        }
        else {
            console.log("ID not found");
        }
    }, [props.match.params.id]);

    // Get customer's data from API
    const getData = async id => {
        let resp = await getCustomer(id);
        if (resp[0] && resp[0].status === 200) {
            const customer = resp[0].data;
            setValue("customer_id", customer.customer_id);
            setValue("name_person", customer.name_person);
            setValue("phone_person", customer.phone_person);
            setValue("name_couple", customer.name_couple);
            setValue("phone_couple", customer.phone_couple);
            setValue("address", customer.address);
            setValue("details", customer.details);
            setValue("rt_rw", customer.rt_rw);
            setValue("kelurahan", customer.kelurahan);
            setValue("kecamatan", customer.kecamatan);
            setValue("phone_home", customer.phone_home);

            setState({
                ...state,
                ktp_photo: (customer.id_card !== undefined)? customer.id_card : "",
                kk_photo: (customer.family_card !== undefined)? customer.family_card : "",
            });
        }
        else {
            if ( resp[1] && resp[1].status === 401 ) setOpenJWT(true);
            setIsLoading(false);
            console.log("Couldn't get customer's data");
        }
        setIsLoading(false);
    }

    // Handle toggle edit button
    const onClickEdit = () => {
        if ( edit === "Edit Kustomer" ) {
            setEdit("Cancel");
            setPageTitle("Edit Kustomer")
            setHide("");
            setNonHide("hide");
            setIsDisabled(false);
        }
        else {
            history.go(0);
        }
    }

    // Make the website stay still for __ ms
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }

    // Close success toast
    const handleCloseSuccess = (event, reason) => {
        setOpenSuccessAlert(false);
    };
    // Close error toast
    const handleCloseError = (event, reason) => {
        setOpenErrorAlert(false);
    };

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }

    // Send updated data customer to server
    const onSubmit = async (data, event) => {
        if ( !event.nativeEvent.submitter.className.includes('delete-btn') ) {
            setIsLoading(true);
            let resp = await editCustomer(idCustomer, data);
            if (resp[0] && resp[0].status === 200) {
                setOpenSuccessAlert(true);
                await sleep(1000);
                history.go(0);
            }
            else {
                setOpenErrorAlert(true);
                setIsLoading(false);
            }
        }
    }

    return (
        <div className="content">
            { isLoading ? ( <Spinner /> ) : "" }
            <Sidebar sidebarState={(windowWidth < 768)? isSidebarOpen : ""} />

            <Modal
                open={openJWT}
                modalType="handle-jwt"
            />

            <Grid container className="responsive-header">
                <Grid item xs={6}>
                    <img src={whiteLogo} alt="logo" className="header-logo" />
                </Grid>
                <Grid item xs={6} className="menu-icon-btn">
                    <Button variant="outlined" onClick={toggleSidebar}>
                        {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                    </Button>
                </Grid>
            </Grid>

            <Grid container className="grid-container">
                <Snackbar open={openSuccessAlert} autoHideDuration={2500} onClose={handleCloseSuccess}>
                    <Alert severity="success">
                        Berhasil Update Kustomer
                    </Alert>
                </Snackbar>
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseError}>
                    <Alert severity="error">
                        Gagal Update Kustomer
                    </Alert>
                </Snackbar>

                <Grid item xs={12}>
                    <form onSubmit={handleSubmit(onSubmit)} className="add-form">
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <h1 style={{ marginBottom: "2rem" }}>{pageTitle}</h1>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Button
                                    variant="contained" onClick={onClickEdit}
                                    className={`edit-btn ${nonHide}`}>
                                    {edit}
                                </Button>
                            </Grid>
                        </Grid>
                        
                        <Grid container>
                            <Grid item xs={12}>
                                <TextInput
                                    label="ID Kustomer"
                                    name="customer_id"
                                    placeholder="ID Kustomer"
                                    className="full-width add-margin-bottom"
                                    validation={register}
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {/* Input for PIC name */}
                                <TextInput
                                    label="Nama Penanggung Jawab"
                                    name="name_person"
                                    placeholder="Nama"
                                    className="form-left-field"
                                    validation={register({ required: "*nama penanggung jawab harus diisi" })}
                                    errors={errors.name_person}
                                    errorClassName="text-required-half"
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {/* Input for PIC phone */}
                                <PhoneInput
                                    label="Nomor Telpon Penanggung Jawab"
                                    name="phone_person"
                                    placeholder="Nomor Telepon"
                                    className="form-right-field"
                                    validation={register}
                                    requiredText="*nomor telpon harus diisi"
                                    errors={errors.phone_person}
                                    errorClassName="text-required-half half-right"
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {/* Input for couple name */}
                                <TextInput
                                    label="Nama Lengkap Suami/Istri"
                                    name="name_couple"
                                    placeholder="Nama"
                                    className="form-left-field"
                                    validation={register}
                                    errors={errors.name_couple}
                                    errorClassName="text-required-half"
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {/* Input for couple phone */}
                                <PhoneInput
                                    label="Nomor Telpon Suami/Istri"
                                    name="phone_couple"
                                    placeholder="Nomor Telepon"
                                    className="form-right-field"
                                    validation={register}
                                    requiredText={false}
                                    errors={errors.phone_couple}
                                    errorClassName="text-required-half half-right"
                                    disabled={isDisabled}
                                />
                            </Grid>
                        </Grid>

                        <h2 style={{ marginBottom: "2rem" }}>Alamat Tinggal Lengkap</h2>

                        <Grid container>
                            <Grid item xs={12}>
                                {/* Input for address */}
                                <TextInput
                                    label="Alamat"
                                    name="address"
                                    placeholder="Alamat"
                                    className="full-width add-margin-bottom"
                                    validation={register({ required: "*alamat harus diisi" })}
                                    errors={errors.address}
                                    errorClassName="text-required"
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {/* Input for address optional */}
                                <TextInput
                                    multiline={true}
                                    rows={3}
                                    label="Keterangan (optional)"
                                    name="details"
                                    placeholder="Contoh: pagar warna merah"
                                    className="full-width add-margin-bottom"
                                    validation={register}
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {/* Input for RT/RW */}
                                <TextInput
                                    label="RT/RW"
                                    name="rt_rw"
                                    placeholder="RT/RW"
                                    className="form-left-field"
                                    validation={register({ required: "*RT/RW harus diisi" })}
                                    errors={errors.rt_rw}
                                    errorClassName="text-required-half"
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {/* Input for kelurahan */}
                                <TextInput
                                    label="Kelurahan"
                                    name="kelurahan"
                                    placeholder="Kelurahan"
                                    className="form-right-field"
                                    validation={register({ required: "*kelurahan harus diisi" })}
                                    errors={errors.kelurahan}
                                    errorClassName="text-required-half half-right"
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {/* Input for kecamatan */}
                                <TextInput
                                    label="Kecamatan"
                                    name="kecamatan"
                                    placeholder="Kecamatan"
                                    className="full-width add-margin-bottom"
                                    validation={register({ required: "*kecamatan harus diisi" })}
                                    errors={errors.kecamatan}
                                    errorClassName="text-required"
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {/* Input for home phone */}
                                <PhoneInput
                                    label="Nomor Telpon Rumah"
                                    name="phone_home"
                                    placeholder="Nomor Telepon"
                                    className="full-width add-margin-bottom"
                                    validation={register}
                                    requiredText="*nomor telpon rumah harus diisi"
                                    errors={errors.phone_home}
                                    errorClassName="text-required"
                                    disabled={isDisabled}
                                />
                            </Grid>
                        </Grid>

                        <Grid container className="image-form">
                            <Grid item xs={12} style={{ marginTop: (isDisabled? "3rem":"0") }}>
                                <p className={`input-img-terms ${isDisabled? "hide":""}`}>
                                    * Tipe file yang diperbolehkan hanya JPG/PNG (maks. 2MB)
                                </p>
                            </Grid>
                            <Grid item xs={12} md={6} className="add-margin-bottom">
                                <ImageInput
                                    name="id_card"
                                    label="Foto KTP"
                                    validation={register}
                                    isEdit={true}
                                    control={control}
                                    errors={errors.id_card}
                                    error_label="*foto KTP"
                                    defaultImg={state.ktp_photo}
                                    overallClassName="half-input-image"
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} className="add-margin-bottom">
                                <ImageInput
                                    name="family_card"
                                    label="Foto KK"
                                    validation={register}
                                    isEdit={true}
                                    control={control}
                                    errors={errors.family_card}
                                    error_label="*foto KK"
                                    defaultImg={state.kk_photo}
                                    overallClassName="half-input-image"
                                    disabled={isDisabled}
                                />
                            </Grid>
                        </Grid>

                        <Grid container style={{ marginTop: "-20px" }}>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    onClick={onClickEdit}
                                    className={`add-cancel-btn ${hide}`}
                                >Cancel</Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    style={{ float: "right", marginRight: "15px" }}
                                    className={`add-new-btn responsive-submit-btn ${hide}`}
                                >Submit</Button>
                            </Grid>
                        </Grid>

                    </form>
                </Grid>
            </Grid>
        </div>
    );
}