import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import MuiAlert from '@material-ui/lab/Alert';
import whiteLogo from '../assets/img/rentatoy-logo-white.png';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import '../styles/Form_Styles.css';
import Modal from '../components/Modal_Com';
import Sidebar from '../components/Sidebar_Com';
import Spinner from '../components/Loading_Com';
import { changePassword } from '../logic/APIHandler';
import { Grid, Button, Snackbar, TextField, InputAdornment, IconButton } from '@material-ui/core';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function ChangePassword() {

    const history = useHistory();
    const { register, handleSubmit, errors } = useForm();
    const [showOldPassword, setShowOldPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [openJWT, setOpenJWT] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const windowWidth = window.innerWidth;

    useEffect(() => {
        const token = localStorage.getItem("token");
        localStorage.clear();
        localStorage.setItem("token", token);
    }, [])

    // Make the website stay still for __ ms
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }

    // Close success toast
    const handleCloseSuccess = (event, reason) => {
        setOpenSuccessAlert(false);
    };
    // Close error toast
    const handleCloseError = (event, reason) => {
        setOpenErrorAlert(false);
    };

    // Toggle show password
    const handleShowOldPassword = () => {
        setShowOldPassword(!showOldPassword);
    }
    const handleShowNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    }

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }

    // Send new executive board (pengurus) data to server
    const onSubmit = async (data) => {
        setIsLoading(true);
        let resp = await changePassword(data);
        if (resp[0] && resp[0].status === 200) {
            setOpenSuccessAlert(true);
            await sleep(1000);
            history.go(0);
        }
        else {
            if ( resp[1].status === 400 ) { setAlertMessage("Password minimal 8 karakter. Cek kembali juga password lama Anda."); }
            else if ( resp[1].status === 401 ) { setOpenJWT(true); setAlertMessage("JWT Expired") }
            else { setAlertMessage("Gagal mengganti password. Cek koneksi internet Anda."); }
            setOpenErrorAlert(true);
            setIsLoading(false);
        }
    }

    return (
        <>
            <div className="content">
                { isLoading ? ( <Spinner /> ) : "" }
                <Sidebar sidebarState={(windowWidth < 768)? isSidebarOpen : ""} />

                <Modal
                    open={openJWT}
                    modalType="handle-jwt"
                />

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <img src={whiteLogo} alt="logo" className="header-logo" />
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={toggleSidebar}>
                            {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                        </Button>
                    </Grid>
                </Grid>

                <Grid container className="grid-container">
                    <Snackbar open={openSuccessAlert} autoHideDuration={2500} onClose={handleCloseSuccess}>
                        <Alert severity="success">
                            Berhasil Mengubah Password
                        </Alert>
                    </Snackbar>
                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseError}>
                        <Alert severity="error">
                            {alertMessage}
                        </Alert>
                    </Snackbar>
                    <Grid item xs={12}>
                        <form onSubmit={handleSubmit(onSubmit)} className="add-form">
                            <h1 style={{ marginBottom: "2rem" }}>Ganti Password</h1>

                            <Grid container>
                                <Grid item xs={12}>
                                    {/* Input for old password */}
                                    <TextField
                                        autoComplete="off"
                                        type={showOldPassword ? "text" : "password"}
                                        variant="outlined"
                                        label="Password Lama"
                                        name="old_password"
                                        placeholder="Password Lama"
                                        className="full-width add-margin-bottom"
                                        inputRef={register({ required: "*password lama harus diisi" })}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleShowOldPassword}
                                                    >
                                                        {showOldPassword? <Visibility/> : <VisibilityOff/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    {errors.old_password && <p className="text-required">{errors.old_password.message}</p>}
                                </Grid>
                                <Grid item xs={12}>
                                    {/* Input for new password */}
                                    <TextField
                                        autoComplete="off"
                                        type={showNewPassword ? "text" : "password"}
                                        variant="outlined"
                                        label="Password Baru"
                                        name="new_password"
                                        placeholder="Password Baru"
                                        className="full-width add-margin-bottom"
                                        inputRef={register({ required: "*password baru harus diisi" })}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={handleShowNewPassword}
                                                    >
                                                        {showNewPassword? <Visibility/> : <VisibilityOff/>}
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    {errors.new_password && <p className="text-required">{errors.new_password.message}</p>}
                                </Grid>
                            </Grid>

                            <Button
                                type="submit"
                                variant="contained"
                                style={{ display: "block", marginLeft: "auto" }}
                                className="add-new-btn"
                            >Submit</Button>

                        </form>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}