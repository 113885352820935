import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import Modal from '@material-ui/core/Modal';
import { Button, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { deleteProduct, deleteCustomer } from '../logic/APIHandler';
import '../styles/Form_Styles.css';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function CustomModal(props) {

    const history = useHistory();
    const { handleSubmit } = useForm();
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);

    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }

    // Close success toast
    const handleCloseSuccess = (event, reason) => {
        setOpenSuccessAlert(false);
    };
    // Close error toast
    const handleCloseError = (event, reason) => {
        setOpenErrorAlert(false);
    };

    // Handle onSubmit modal
    const onSubmit = async data => {
        let resp = "";
        if ( props.modalType === 'delete-product' ) {
            resp = await deleteProduct(props.id);
        }
        else if ( props.modalType === 'delete-customer' ) {
            resp = await deleteCustomer(props.id);
        }
        if ( resp[0] && resp[0].status === 200 ) {
            setOpenSuccessAlert(true);
            await sleep(1000);
            history.go(0);
        }
        else {
            setOpenErrorAlert(true);
        }
    }

    // HTML for Modal
    const modalBody = (
        <div className="modal-body">
            {props.modalType === "handle-jwt"?
                <>
                    <h2 id="simple-modal-title" className="jwt-title">Token Anda Sudah Expire</h2>
                    <NavLink to="/" style={{ textDecoration: "none" }}>
                        <Button variant="contained" className="jwt-btn">Login Kembali</Button>
                    </NavLink>
                </>
                :
                <>
                    <h2 id="simple-modal-title">{props.modalTitle}</h2>
                    <form onSubmit={handleSubmit(onSubmit)} className="custom-form">
                            {(props.modalType.includes('delete'))?
                                <p style={{ marginBottom: "30px" }}>Nama: {props.dataName}</p>
                            : "" }
                        <Button onClick={props.onClickCancel} variant="contained" className="modal-cancel-btn">{props.cancelText}</Button>
                        <Button type="submit" variant="contained" color={props.color} className="modal-submit-btn">{props.submitText}</Button>
                    </form>
                </>
            }
        </div>
    );

    return (
        <>
            <Modal
                open={props.open}
                onClose={props.onClose}
                className="custom-modal"
                aria-labelledby="simple-modal-title"
                aria-describedby="simple-modal-description"
            >
                {modalBody}
            </Modal>
            <Snackbar open={openSuccessAlert} autoHideDuration={2500} onClose={handleCloseSuccess}>
                <Alert severity="success">
                    {props.successText}
                </Alert>
            </Snackbar>
            <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseError}>
                <Alert severity="error">
                    {props.errorText}
                </Alert>
            </Snackbar>
        </>
    );
}