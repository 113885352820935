import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { Grid, Button, Switch } from "@material-ui/core";
import whiteLogo from "../../assets/img/rentatoy-logo-white.png";
import MenuIcon from "@material-ui/icons/Menu";
import CloseIcon from "@material-ui/icons/Close";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import DeleteIcon from "@material-ui/icons/Delete";
import Sidebar from "../../components/Sidebar_Com";
import Table from "../../components/Table_Com";
import Modal from "../../components/Modal_Com";
import Spinner from "../../components/Loading_Com";
import { getProducts, updateProductStatus } from "../../logic/APIHandler";

class Product extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      open: false,
      openJWT: false,
      productId: "00000000",
      productName: "",
      isLoading: false,
      isSidebarOpen: "slide-out"
    };
    this.windowWidth = window.innerWidth;
    this.toggleSidebar = this.toggleSidebar.bind(this);
    this.handleClose = this.handleClose.bind(this);
  }

  columns = [
    {
      title: "Kode",
      field: "product_code",
      tooltip: "Kode produk"
    },
    {
      title: "Nama",
      field: "title",
      tooltip: "Nama produk",
      cellStyle: {
        width: "300px",
        maxWidth: "300px"
      }
    },
    {
      title: "Harga",
      field: "price",
      tooltip: "Harga produk"
    },
    {
      title: "Foto Produk",
      field: "product_image",
      tooltip: "Foto produk",
      render: rowData =>
        rowData.product_image ? (
          <img
            src={rowData.product_image}
            width="80"
            height="80"
            alt="Produk"
            style={{ objectFit: "cover", objectPosition: "center" }}
          />
        ) : (
          <p>Tidak ada gambar</p>
        )
    },
    {
      title: "Ketersediaan Produk",
      field: "isAvailable",
      tooltip: "Menyala = Tersedia, Mati = Tidak Tersedia",
      cellStyle: {
        textAlign: "center"
      },
      render: rowData => (
        <Switch
          checked={rowData.isAvailable}
          onChange={this.toggleIsAvailable.bind(this)}
          id={rowData._id}
        />
      )
    }
  ];

  actions = [
    {
      icon: () => <FindInPageIcon />,
      tooltip: "Lihat Detil Produk",
      onClick: (event, rowData) => {
        this.props.history.push({
          pathname: "/produk/" + rowData._id
        });
      }
    },
    {
      icon: () => <DeleteIcon />,
      tooltip: "Hapus Produk",
      onClick: (event, rowData) => {
        this.handleOpen(rowData);
      }
    }
  ];

  // Show delete product modal
  handleOpen(product) {
    this.setState({
      open: true,
      productId: product._id,
      productName: product.title
    });
  }

  // CLose delete product modal
  handleClose() {
    this.setState({
      open: false
    });
  }

  // Toggle sidebar menu in responsive
  toggleSidebar() {
    if (this.state.isSidebarOpen === "slide-out") {
      this.setState({
        isSidebarOpen: "slide-in"
      });
    } else {
      this.setState({
        isSidebarOpen: "slide-out"
      });
    }
  }

  // Handle switch button
  async toggleIsAvailable(event, data) {
    await updateProductStatus(event.target.id, data);
    this.componentDidMount();
  }

  async getData() {
    this.setState({ isLoading: true });
    let products = await getProducts();
    if ( products[1] && products[1].status == 401) {
        this.setState({
            openJWT: true,
            isLoading: false,
        });
    }
    else {
        this.setState({
          data: products[0] ? products[0].data : [],
          isLoading: false,
        });
    }
  }

  componentDidMount() {
    localStorage.removeItem("customerPage");
    this.getData();
  }

  render() {
    return (
      <div className="content">
        {/* {this.state.isLoading ? <Spinner /> : ""} */}
        <Sidebar
          sidebarState={this.windowWidth < 768 ? this.state.isSidebarOpen : ""}
        />

        <Modal
          open={this.state.open}
          onClose={this.handleClose}
          onClickCancel={this.handleClose}
          modalType="delete-product"
          id={this.state.productId}
          color="secondary"
          modalTitle="Hapus produk?"
          dataName={this.state.productName}
          submitText="Delete"
          cancelText="Cancel"
          successText="Berhasil menghapus produk!"
          errorText="Gagal menghapus produk!"
        />

        <Modal
          open={this.state.openJWT}
          modalType="handle-jwt"
        />

        <Grid container className="responsive-header">
          <Grid item xs={6}>
            <img src={whiteLogo} alt="logo" className="header-logo" />
          </Grid>
          <Grid item xs={6} className="menu-icon-btn">
            <Button variant="outlined" onClick={this.toggleSidebar}>
              {this.state.isSidebarOpen === "slide-out" ? (
                <MenuIcon />
              ) : (
                <CloseIcon />
              )}
            </Button>
          </Grid>
        </Grid>

        { this.state.isLoading ? ( <Spinner /> ) : <Grid container className="grid-container">
          <Grid item xs={12} className="material-table">
            <Table
              tableTitle="Tabel Produk"
              tableColumns={this.columns}
              data={this.state.data}
              tableActions={this.actions}
              type="product"
              productPage={localStorage.getItem("productPage")?
                localStorage.getItem("productPage") : 0}
              productSearch={localStorage.getItem("productSearch")?
                localStorage.getItem("productSearch") : ""}
            />
          </Grid>
          <Grid item xs={12} className="table-footer">
            <NavLink
              className="btn-link"
              to="/produk-form"
              style={{ textDecoration: "none" }}
            >
              <Button variant="contained" className="add-btn-table">
                Tambah Produk
              </Button>
            </NavLink>
          </Grid>
        </Grid> }
      </div>
    );
  }
}

export default Product;
