import React from "react";
import "./App.css";
import { Route, Redirect } from "react-router-dom";

export const PrivateRoute = ({ component: Component, access: Access, status:Status, ...rest }) => {
  return (
    <Route
      {...rest}
      render={props => {
          if ( localStorage.getItem('token') ) {
              return <Component status={Status} {...props}/>
          }
          else {
            return <Redirect to="/"/>
          }
        }
      }
    />
  );
}