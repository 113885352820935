import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { Container, Grid, TextField, Button, InputAdornment,
    IconButton, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import Spinner from '../components/Loading_Com';
import { hitLogin } from '../logic/APIHandler';
import '../styles/Login_Styles.css';
import logo from '../assets/img/rentatoy-logo.png';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function LoginPage(props) {

    const history = useHistory();
    const [showPassword, setShowPassword] = useState(false);
    const { register, handleSubmit, errors } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [errorText, setErrorText] = useState('');

    useEffect(() => {
        localStorage.clear();
    }, []);

    // Toggle show password
    const handleShowPassword = () => {
        setShowPassword(!showPassword)
    }

    // Close error toast
    const handleCloseError = (event, reason) => {
        setOpenErrorAlert(false);
    };

    // Handle log in
    const onSubmit = async data => {
        setIsLoading(true);
        let resp = await hitLogin(data);
        if ( resp[0] ) {
            localStorage.setItem('token', resp[0].data.token);
            history.push("/produk");
        }
        else {
            setErrorText(resp[1].message);
            setOpenErrorAlert(true);
            setIsLoading(false);
        }
    }

    return (
        <Container className="login-container">
            { isLoading ? ( <Spinner /> ) : "" }
            <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseError}>
                <Alert severity="error">
                    {errorText}
                </Alert>
            </Snackbar>
            <Grid container>
                <Grid item xs={12}>
                    <form id="login-form" onSubmit={handleSubmit(onSubmit)}>
                        <img src={logo} alt="logo" className="login-logo" />
                        <TextField
                            label="Username"
                            name="username"
                            placeholder="Username"
                            className="login-input"
                            inputRef={register({ required: "*username harus diisi" })}
                            />
                        {errors.username && <p className="text-required">{errors.username.message}</p>}
                        <TextField
                            type={showPassword ? "text" : "password"}
                            label="Password"
                            name="password"
                            placeholder="Password"
                            className="login-input"
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleShowPassword}
                                        >
                                            {showPassword? <Visibility/> : <VisibilityOff/>}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                            inputRef={register({ required: "*password harus diisi" })}
                        />
                        {errors.password && <p className="text-required">{errors.password.message}</p>}

                        <Button type="submit" variant="contained" id="login-btn">
                            Submit
                        </Button>
                    </form>
                </Grid>
            </Grid>
        </Container>
    );
}