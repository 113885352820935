import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import MuiAlert from '@material-ui/lab/Alert';
import whiteLogo from '../../assets/img/rentatoy-logo-white.png';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import '../../styles/Form_Styles.css';
import Modal from '../../components/Modal_Com';
import Sidebar from '../../components/Sidebar_Com';
import Spinner from '../../components/Loading_Com';
import { getProduct, getCategories, editProduct, postProductSlider, updateProductStatus,
    deleteProductSlider, } from '../../logic/APIHandler';
import { Button, Grid, Snackbar } from '@material-ui/core';
import { TextInput, PriceInput, ImageInput, GroupedDropdown, TagsInput, SwitchInput } from '../../logic/FormInput';
import _ from 'underscore';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function DetailProduct(props) {

    const history = useHistory();
    const { register, handleSubmit, errors, control, setValue, } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [data, setData] = useState([]);
    const [openJWT, setOpenJWT] = useState(false);
    const [edit, setEdit] = useState('Edit Produk');
    const [pageTitle, setPageTitle] = useState('Detil Produk');
    const [hide, setHide] = useState('hide');
    const [nonHide, setNonHide] = useState('');
    const [isDisabled, setIsDisabled] = useState(true);
    const [idProduct, setIDProduct] = useState(null);
    const [deletedImage, setDeletedImage] = useState([]);
    const [eState, setEState] = useState("");
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const windowWidth = window.innerWidth;
    const [state, setState] = useState({
        tags: [],
        product_image: "",
        product_slider_1: "",
        product_slider_2: "",
        product_slider_3: "",
        isAvailable: false,
        yes_no: 'Tersedia',
    });

    // Method that run first when the page is opened 
    useEffect(() => {
        const idProduct = props.match.params.id;
        setIDProduct(idProduct);
        if ( idProduct ) {
            getData(idProduct);
        }
        else {
            console.log("ID not found");
        }
    }, [props.match.params.id]);

    // Get product's data from API
    const getData = async id => {
        setIsLoading(true);
        let categories = await getCategories();
        if (categories[0]) {
            const categoryList = categories[0].data;
            let res = [];
            categoryList.forEach(category => {
                let subs = [];
                category.sub_category.forEach(sub_categ => {
                    if ( sub_categ.sub !== "Musical Mobile & Hanging Toys" ) {
                        subs.push({
                            'id': sub_categ.id,
                            'label': sub_categ.sub,
                            'value': sub_categ.sub,
                        })
                    }
                })
                res.push({
                    'label': category.category,
                    'options': subs,
                })
            })
            setData(res);
        }
        else {
            if ( categories[1] && categories[1].status === 401 ) setOpenJWT(true);
            setIsLoading(false);
        }

        let resp = await getProduct(id);
        if (resp[0] && resp[0].status === 200) {
            const product = resp[0].data;
            setValue("title", product.title);
            setValue("product_code", product.product_code);
            setValue("price", product.price);
            
            // Create default value for text editor
            const html = product.description;
            const contentBlock = htmlToDraft(html);
            if (contentBlock) {
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                setEState(editorState);
            }

            let defaultCategories = [];
            product.categories.forEach(category => {
                defaultCategories.push({
                    id: category.id, value: category.name, label: category.name,
                })
            })
            setValue("categories", defaultCategories);

            setState({
                ...state,
                tags: product.tags,
                product_image: (product.product_image !== undefined)? product.product_image : "",
                product_slider_1: (product.product_slider[0] !== undefined)?
                    { value: product.product_slider[0], idx: 0 } : "",
                product_slider_2: (product.product_slider[1] !== undefined)?
                    { value: product.product_slider[1], idx: 1 } : "",
                product_slider_3: (product.product_slider[2] !== undefined)?
                    { value: product.product_slider[2], idx: 2 } : "",
                isAvailable: product.isAvailable,
                yes_no: (product.isAvailable? 'Tersedia':'Tidak'),
            });
        }
        else {
            setIsLoading(false);
        }
        setIsLoading(false);
    }

    // Handle switch input event
    const handleChange = (event) => {
        setState({
            ...state,
            yes_no: (event.target.checked? 'Tersedia' : 'Tidak'),
            [event.target.name]: event.target.checked,
        });
    };

    // Handle toggle edit button
    const onClickEdit = () => {
        if ( edit === "Edit Produk" ) {
            setEdit("Cancel");
            setPageTitle("Edit Produk")
            setHide("");
            setNonHide("hide");
            setIsDisabled(false);
        }
        else {
            history.go(0);
        }
    }

    // Make the website stay still for __ ms
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }

    // Close success toast
    const handleCloseSuccess = (event, reason) => {
        setOpenSuccessAlert(false);
    };
    // Close error toast
    const handleCloseError = (event, reason) => {
        setOpenErrorAlert(false);
    };

    // Handle delete and add tags
    const handleDeleteChip = (chip) => {
        setState({
            ...state,
            tags: _.without(state.tags, chip)
        })
    }
    const handleAddChip = (chip) => {
        setState({
            ...state,
            tags: [...state.tags, chip]
        });
    }

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }

    // Handle onChange text editor value
    const onEditorStateChange = (editorState) => {
        setEState(editorState);
    };

    // Handle onBlur product code field
    const handleProductCode = (event) => {
        let value = event.target.value;
        if ( value && value.length === 1 ) {
            event.target.value = "00" + value;
        }
        else if ( value && value.length === 2 ) {
            event.target.value = "0" + value;
        }
    }

    // Store deleted image
    const addDeletedImage = (name) => {
        let value = deletedImage.concat(name);
        setDeletedImage(value);
    }

    // Send updated data product to server
    const onSubmit = async (data, event) => {
        if ( !event.nativeEvent.submitter.className.includes('delete-btn') ) {
            setIsLoading(true);
            let description = draftToHtml(convertToRaw(eState.getCurrentContent()));

            if ( deletedImage.includes("product_slider_1")
                || (state.product_slider_1.length > 0 && data.product_slider_1) ) {
                await deleteProductSlider(idProduct, 0);
            }
            if ( deletedImage.includes("product_slider_2")
                || (state.product_slider_2.length > 0 && data.product_slider_2) ) {
                await deleteProductSlider(idProduct, 1);
            }
            if ( deletedImage.includes("product_slider_3")
                || (state.product_slider_3.length > 0 && data.product_slider_3) ) {
                await deleteProductSlider(idProduct, 2);
            }

            await postProductSlider(idProduct, data);
            await updateProductStatus(idProduct, data.isAvailable);
            let resp = await editProduct(idProduct, data, description, state.tags);
            if (resp[0] && resp[0].status === 200) {
                setOpenSuccessAlert(true);
                await sleep(1000);
                history.go(0);
            }
            else {
                setOpenErrorAlert(true);
                setIsLoading(false);
            }
        }
    }

    return (
        <div className="content">
            { isLoading ? ( <Spinner /> ) : "" }
            <Sidebar sidebarState={(windowWidth < 768)? isSidebarOpen : ""} />

            <Modal
                open={openJWT}
                modalType="handle-jwt"
            />

            <Grid container className="responsive-header">
                <Grid item xs={6}>
                    <img src={whiteLogo} alt="logo" className="header-logo" />
                </Grid>
                <Grid item xs={6} className="menu-icon-btn">
                    <Button variant="outlined" onClick={toggleSidebar}>
                        {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                    </Button>
                </Grid>
            </Grid>

            <Grid container className="grid-container">
                <Snackbar open={openSuccessAlert} autoHideDuration={2500} onClose={handleCloseSuccess}>
                    <Alert severity="success">
                        Berhasil Update Produk
                    </Alert>
                </Snackbar>
                <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseError}>
                    <Alert severity="error">
                        Gagal Update Produk/Foto
                    </Alert>
                </Snackbar>

                <Grid item xs={12}>
                    <form onSubmit={handleSubmit(onSubmit)} className="add-form">
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <h1 style={{ marginBottom: "2rem" }}>{pageTitle}</h1>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <Button
                                    variant="contained" onClick={onClickEdit}
                                    className={`edit-btn ${nonHide}`}>
                                    {edit}
                                </Button>
                            </Grid>
                        </Grid>
                        
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                {/* Input for name */}
                                <TextInput
                                    label="Nama Produk"
                                    name="title"
                                    placeholder="Nama Produk"
                                    className="form-left-field"
                                    validation={register({ required: "*nama harus diisi" })}
                                    errors={errors.title}
                                    errorClassName="text-required-half"
                                    disabled={isDisabled}
                                    />
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {/* Input for product code */}
                                <TextInput
                                    label="Kode Produk"
                                    name="product_code"
                                    placeholder="Contoh: 001"
                                    className="form-right-field"
                                    validation={register({
                                        required: "*kode produk harus diisi",
                                        pattern: {
                                            value: /^[0-9]*$/,
                                            message: "*input hanya boleh berupa angka",
                                        },
                                        minLength: {
                                            value: 3,
                                            message: "*kode produk tidak boleh kurang dari 3 digit angka",
                                        },
                                    })}
                                    inputProps={{
                                        maxLength: 3,
                                    }}
                                    onBlur={handleProductCode}
                                    errors={errors.product_code}
                                    errorClassName="text-required-half half-right"
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ marginBottom: "20px" }}>
                                {/* Input for description */}
                                <Editor
                                    placeholder="Tulis deskripsi barang dengan lengkap di sini"
                                    editorState={eState}
                                    wrapperClassName={`demo-wrapper ${isDisabled? 'disabled-wrapper':''}`}
                                    editorClassName="demo-editor"
                                    onEditorStateChange={onEditorStateChange}
                                    readOnly={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {/* Input for price */}
                                <PriceInput
                                    label="Harga Produk"
                                    name="price"
                                    className={`
                                        full-width add-margin-bottom price-field 
                                        ${ isDisabled? 'disabled-field' : '' }
                                    `}
                                    control={control}
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12} style={{ zIndex: "99" }}>
                                {/* Input for categories */}
                                <GroupedDropdown
                                    label="Kategori Produk"
                                    name="categories"
                                    className={`
                                        full-width add-margin-bottom grouped-field 
                                        ${ isDisabled? 'disabled-field' : '' }
                                    `}
                                    control={control}
                                    options={data}
                                    rules={{ required: "*pilih minimal 1 kategori" }}
                                    errors={errors.categories}
                                    errorClassName="text-required"
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {/* Input for tags */}
                                <TagsInput
                                    label="Tags"
                                    name="tags"
                                    placeholder="Ketik tags di sini"
                                    className={`
                                        full-width 
                                        ${ isDisabled? 'disabled-field add-margin-bottom' : '' }
                                    `}
                                    value={state.tags}
                                    onAdd={(chip) => handleAddChip(chip)}
                                    onDelete={(chip, index) => handleDeleteChip(chip, index)}
                                    disabled={isDisabled}
                                />
                                <p className={`tag-info ${isDisabled? "hide":""}`}>* Tekan enter/spasi untuk memasukkan tag</p>
                            </Grid>
                            <Grid item xs={12}>
                                {/* Input for isAvailable */}
                                <SwitchInput
                                    legend="Produk Tersedia?"
                                    label={state.yes_no}
                                    name="isAvailable"
                                    className={`switch-layout add-margin-bottom ${isDisabled? "disabled-border":""}`}
                                    value={state.isAvailable}
                                    checked={state.isAvailable}
                                    onChange={handleChange}
                                    validation={register}
                                    disabled={isDisabled}
                                />
                            </Grid>
                        </Grid>

                        <Grid container className="product-gallery">
                            <Grid item xs={12}>
                                <h2>Foto Utama</h2>
                                <p className={`input-img-terms ${isDisabled? "hide":""}`}>
                                    * Tipe file yang diperbolehkan hanya JPG/PNG (maks. 2MB)
                                </p>
                            </Grid>
                            <Grid item xs={12} md={3} lg={4}></Grid>
                            <Grid item xs={12} md={6} lg={4} className="add-margin-bottom">
                                <ImageInput
                                    name="product_image"
                                    label="Foto Produk"
                                    validation={register}
                                    errors={errors.product_image}
                                    isEdit={true}
                                    control={control}
                                    defaultImg={state.product_image}
                                    disabled={isDisabled}
                                />
                            </Grid>
                            <Grid item xs={12} md={3}></Grid>
                        </Grid>

                        <Grid container className="product-gallery">
                            <Grid item xs={12} style={{ marginBottom: "15px" }}>
                                <h2>Foto Tambahan</h2>
                                <p className={`input-img-terms ${isDisabled? "hide":""}`}>
                                    * Tipe file yang diperbolehkan hanya JPG/PNG (maks. 2MB)
                                </p>
                            </Grid>
                            <Grid item xs={12} md={6} lg={4} className="add-margin-bottom">
                                <ImageInput
                                    name="product_slider_1"
                                    label="Foto 1"
                                    validation={register}
                                    isEdit={true}
                                    control={control}
                                    disabled={isDisabled}
                                    defaultImg={state.product_slider_1.value}
                                    storeDeletedImage={addDeletedImage}
                                    id={idProduct}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={4} className="add-margin-bottom">
                                <ImageInput
                                    name="product_slider_2"
                                    label="Foto 2"
                                    validation={register}
                                    isEdit={true}
                                    control={control}
                                    disabled={isDisabled}
                                    defaultImg={state.product_slider_2.value}
                                    storeDeletedImage={addDeletedImage}
                                    id={idProduct}
                                />
                            </Grid>
                            <Grid item xs={12} md={6} lg={4} className="add-margin-bottom">
                                <ImageInput
                                    name="product_slider_3"
                                    label="Foto 3"
                                    validation={register}
                                    isEdit={true}
                                    control={control}
                                    disabled={isDisabled}
                                    defaultImg={state.product_slider_3.value}
                                    storeDeletedImage={addDeletedImage}
                                    id={idProduct}
                                />
                            </Grid>
                        </Grid>

                        <Grid container style={{ marginTop: "-20px" }}>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    onClick={onClickEdit}
                                    className={`add-cancel-btn ${hide}`}
                                >Cancel</Button>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    style={{ float: "right", marginRight: "15px" }}
                                    className={`add-new-btn responsive-submit-btn ${hide}`}
                                >Submit</Button>
                            </Grid>
                        </Grid>

                    </form>
                </Grid>
            </Grid>
        </div>
    );
}