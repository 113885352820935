import React, { useState } from 'react';
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import MuiAlert from '@material-ui/lab/Alert';
import whiteLogo from '../../assets/img/rentatoy-logo-white.png';
import MenuIcon from '@material-ui/icons/Menu';
import CloseIcon from '@material-ui/icons/Close';
import '../../styles/Form_Styles.css';
import Sidebar from '../../components/Sidebar_Com';
import Spinner from '../../components/Loading_Com';
import { postCustomer } from '../../logic/APIHandler';
import { TextInput, PhoneInput, ImageInput, } from '../../logic/FormInput';
import { Grid, Button, Snackbar } from '@material-ui/core';

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function AddCustomer() {

    const history = useHistory();
    const { register, handleSubmit, errors, control } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [openSuccessAlert, setOpenSuccessAlert] = useState(false);
    const [openErrorAlert, setOpenErrorAlert] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = useState('slide-out');
    const windowWidth = window.innerWidth;

    // Make the website stay still for __ ms
    const sleep = (milliseconds) => {
        return new Promise(resolve => setTimeout(resolve, milliseconds));
    }

    // Close success toast
    const handleCloseSuccess = (event, reason) => {
        setOpenSuccessAlert(false);
    };
    // Close error toast
    const handleCloseError = (event, reason) => {
        setOpenErrorAlert(false);
    };

    // Toggle sidebar menu in responsive
    const toggleSidebar = () => {
        if ( isSidebarOpen === 'slide-out' ) {
            setIsSidebarOpen('slide-in');
        }
        else {
            setIsSidebarOpen('slide-out');
        }
    }

    // Send new executive board (pengurus) data to server
    const onSubmit = async (data, event) => {
        if ( !event.nativeEvent.submitter.className.includes('delete-btn') ) {
            setIsLoading(true);
            let resp = await postCustomer(data);
            if (resp[0] && resp[0].status === 200) {
                setOpenSuccessAlert(true);
                await sleep(1000);
                history.replace("/kustomer");
            }
            else {
                setOpenErrorAlert(true);
                setIsLoading(false);
            }
        }
    }

    return (
        <>
            <div className="content">
                { isLoading ? ( <Spinner /> ) : "" }
                <Sidebar sidebarState={(windowWidth < 768)? isSidebarOpen : ""} />

                <Grid container className="responsive-header">
                    <Grid item xs={6}>
                        <img src={whiteLogo} alt="logo" className="header-logo" />
                    </Grid>
                    <Grid item xs={6} className="menu-icon-btn">
                        <Button variant="outlined" onClick={toggleSidebar}>
                            {isSidebarOpen === 'slide-out'? <MenuIcon /> : <CloseIcon />}
                        </Button>
                    </Grid>
                </Grid>

                <Grid container className="grid-container">
                    <Snackbar open={openSuccessAlert} autoHideDuration={2500} onClose={handleCloseSuccess}>
                        <Alert severity="success">
                            Berhasil Membuat Kustomer
                        </Alert>
                    </Snackbar>
                    <Snackbar open={openErrorAlert} autoHideDuration={2000} onClose={handleCloseError}>
                        <Alert severity="error">
                            Gagal Membuat Kustomer
                        </Alert>
                    </Snackbar>
                    <Grid item xs={12}>
                        <form onSubmit={handleSubmit(onSubmit)} className="add-form">
                            <h1 style={{ marginBottom: "2rem" }}>Tambah Kustomer Baru</h1>

                            <Grid container>
                                <Grid item xs={12} md={6}>
                                    {/* Input for PIC name */}
                                    <TextInput
                                        label="Nama Penanggung Jawab"
                                        name="name_person"
                                        placeholder="Nama"
                                        className="form-left-field"
                                        validation={register({ required: "*nama penanggung jawab harus diisi" })}
                                        errors={errors.name_person}
                                        errorClassName="text-required-half"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {/* Input for PIC phone */}
                                    <PhoneInput
                                        label="Nomor Telpon Penanggung Jawab"
                                        name="phone_person"
                                        placeholder="Nomor Telepon"
                                        className="form-right-field"
                                        validation={register}
                                        requiredText="*nomor telpon harus diisi"
                                        errors={errors.phone_person}
                                        errorClassName="text-required-half half-right"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {/* Input for couple name */}
                                    <TextInput
                                        label="Nama Lengkap Suami/Istri"
                                        name="name_couple"
                                        placeholder="Nama"
                                        className="form-left-field"
                                        validation={register}
                                        errors={errors.name_couple}
                                        errorClassName="text-required-half"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {/* Input for couple phone */}
                                    <PhoneInput
                                        label="Nomor Telpon Suami/Istri"
                                        name="phone_couple"
                                        placeholder="Nomor Telepon"
                                        className="form-right-field"
                                        validation={register}
                                        requiredText={false}
                                        errors={errors.phone_couple}
                                        errorClassName="text-required-half half-right"
                                    />
                                </Grid>
                            </Grid>

                            <h2 style={{ marginBottom: "2rem" }}>Alamat Tinggal Lengkap</h2>

                            <Grid container>
                                <Grid item xs={12}>
                                    {/* Input for address */}
                                    <TextInput
                                        label="Alamat"
                                        name="address"
                                        placeholder="Alamat"
                                        className="full-width add-margin-bottom"
                                        validation={register({ required: "*alamat harus diisi" })}
                                        errors={errors.address}
                                        errorClassName="text-required"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {/* Input for optional address */}
                                    <TextInput
                                        multiline={true}
                                        rows={3}
                                        label="Keterangan (optional)"
                                        name="details"
                                        placeholder="Contoh: pagar warna merah"
                                        className="full-width add-margin-bottom"
                                        validation={register}
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {/* Input for RT/RW */}
                                    <TextInput
                                        label="RT/RW"
                                        name="rt_rw"
                                        placeholder="RT/RW"
                                        className="form-left-field"
                                        validation={register({ required: "*RT/RW harus diisi" })}
                                        errors={errors.rt_rw}
                                        errorClassName="text-required-half"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    {/* Input for kelurahan */}
                                    <TextInput
                                        label="Kelurahan"
                                        name="kelurahan"
                                        placeholder="Kelurahan"
                                        className="form-right-field"
                                        validation={register({ required: "*kelurahan harus diisi" })}
                                        errors={errors.kelurahan}
                                        errorClassName="text-required-half half-right"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {/* Input for kecamatan */}
                                    <TextInput
                                        label="Kecamatan"
                                        name="kecamatan"
                                        placeholder="Kecamatan"
                                        className="full-width add-margin-bottom"
                                        validation={register({ required: "*kecamatan harus diisi" })}
                                        errors={errors.kecamatan}
                                        errorClassName="text-required"
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    {/* Input for home phone */}
                                    <PhoneInput
                                        label="Nomor Telpon Rumah"
                                        name="phone_home"
                                        placeholder="Nomor Telepon"
                                        className="full-width add-margin-bottom"
                                        validation={register}
                                        requiredText="*nomor telpon rumah harus diisi"
                                        errors={errors.phone_home}
                                        errorClassName="text-required"
                                    />
                                </Grid>
                            </Grid>

                            <Grid container className="image-form">
                                <Grid item xs={12}>
                                    <p className="input-img-terms">
                                        * Tipe file yang diperbolehkan hanya JPG/PNG (maks. 2MB)
                                    </p>
                                </Grid>
                                <Grid item xs={12} md={6} className="add-margin-bottom">
                                    <ImageInput
                                        name="id_card"
                                        label="Foto KTP"
                                        validation={register}
                                        isEdit={false}
                                        control={control}
                                        errors={errors.id_card}
                                        error_label="*foto KTP"
                                        defaultImg={false}
                                        overallClassName="half-input-image"
                                    />
                                </Grid>
                                <Grid item xs={12} md={6} className="add-margin-bottom">
                                    <ImageInput
                                        name="family_card"
                                        label="Foto KK"
                                        validation={register}
                                        isEdit={false}
                                        control={control}
                                        errors={errors.family_card}
                                        error_label="*foto KK"
                                        defaultImg={false}
                                        overallClassName="half-input-image"
                                    />
                                </Grid>
                            </Grid>

                            <Button
                                type="submit"
                                variant="contained"
                                style={{ display: "block", marginLeft: "auto" }}
                                className="add-new-btn"
                            >Submit</Button>

                        </form>
                    </Grid>
                </Grid>
            </div>
        </>
    );
}